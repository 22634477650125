import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import store from "./redux/store";
import { Provider } from "react-redux";
import { AuthProvider } from "./redux/context/AuthProvider";

// Import CSS styles
import './assets/css/flipbook.css';
import './assets/css/cart-items.css';
import './assets/css/product.css';
import './assets/css/MyAccount.css';
import './assets/css/Loader.css';
import './assets/css/Blog.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'react-toastify/dist/ReactToastify.css';

// Create root and render the application
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <Provider store={store}>
            <AuthProvider>
                <App />
            </AuthProvider>
        </Provider>
    </React.StrictMode>
);

// Measure performance (optional)
reportWebVitals();
