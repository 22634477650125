// jwtUtils.js
export const parseJwt = (token) => {
    try {
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        const jsonPayload = decodeURIComponent(
            atob(base64)
                .split('')
                .map((c) => {
                    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
                })
                .join('')
        );

        return JSON.parse(jsonPayload);
    } catch (error) {
        console.error('Invalid token', error);
        return null;
    }
};

//
export const getPriceAfterDiscount = (productDetailData) =>{
    if(productDetailData){
        if(productDetailData.amount_type ==='percentage'){
            return (productDetailData.sale_price*productDetailData.discount_percentage*0.01 < productDetailData.maximum_discount)
                ? productDetailData.sale_price *(100-productDetailData.discount_percentage)*0.01 : productDetailData.sale_price-productDetailData.maximum_discount
        }else if(productDetailData.amount_type ==='amount'){
            return productDetailData.sale_price-productDetailData.maximum_discount;
        }else{
            return productDetailData.sale_price;
        }
    }
}
//
export const getDiscountPrice = (discount,total) =>{
    if(discount.amount_type ==='percentage' && total * discount.discount_percentage *0.01 < discount.maximum_discount){
       return  total * discount.discount_percentage *0.01;
    }else if(discount.maximum_discount){
        return Number(discount.maximum_discount);
    }
}
/**
 * This method is used to generate random string according to required length
 * @param length
 * @returns {string}
 */
export const generateRandomString=(length)=> {
    var chars = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    var charLength = chars.length;
    var result = '';
    for ( var i = 0; i < length; i++ ) {
        result += chars.charAt(Math.floor(Math.random() * charLength));
    }

    return new Date().getTime()+result;
}

/**
 * This method is used to format price
 * @param amount
 * @returns {string}
 */
export const formatPrice = (amount) => {
    return new Intl.NumberFormat('en-IN', {
        style: 'currency',
        currency: 'INR',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    }).format(amount);
};
