import './App.css';
import React, {Suspense, useEffect, useMemo} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { parseJwt } from "./redux/utility/jwtUtils";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import {
  actionToGetCompanyListApiCall,
  actionToGetMenuListApiCall, actionToGetUserProfileApiCall,
  actionToLoadCart
} from "./redux/action";
import useAuth from "./redux/hooks/useAuth";
import { useEffectOnce } from "./redux/hooks/useEffectOnce";
import HelmetComponent from "./components/layout/HelmetComponent";
import Layout from "./components/layout/Layout";
import RequireAuth from "./components/auth/RequireAuth";
import ErrorPage from "./pages/ErrorPage";
import LazyComponent from "./LazyComponent";
import Loader from "./components/Loader/Loader";
import TagManager from "react-gtm-module";
import {setHeaderForAuthorization} from "./redux/hooks/apiConfig";
import ReactGA from "react-ga4";


const ROLES = {
  SuperAdmin: 1,
  Admin: 2,
  SalesTeam: 3,
  WebsiteEditor: 4,
  InventoryManager: 5,
  Trainer: 6,
  Customer: 7
};

function App() {
  const { setAuth } = useAuth();
  const dispatch = useDispatch();
  const menuListData = useSelector((state) => state.webSetting.menuListData);

  // Memoize allowed roles
  const allowedRoles = useMemo(() => Object.values(ROLES), []);

  // Authorize user and load cart
  const authorized = () => {
    const accessToken = localStorage.getItem('accessToken');
    if (accessToken) {
        setHeaderForAuthorization(accessToken);
        const user = parseJwt(accessToken);
        setAuth(user);
        // Load cart
        const storedCart = JSON.parse(localStorage.getItem('cart'));
        if (storedCart || user.id) {
          dispatch(actionToGetUserProfileApiCall());
          dispatch(actionToLoadCart(storedCart, user.id));
        }
    }
  };

  useEffectOnce(() => {
    dispatch(actionToGetCompanyListApiCall());
    dispatch(actionToGetMenuListApiCall());
    authorized();
  });
  useEffect(() => {
    ReactGA.initialize('G-D1Q2ZQVFN1'); // Replace with your GA4 Measurement ID
    ReactGA.send('pageview'); // Track initial page view
    const tagManagerArgs = {
      gtmId: 'GTM-W4V6BG7F'
    };
    TagManager.initialize(tagManagerArgs);
  }, []);

  // Memoize route configurations
  const routesConfig = useMemo(() => {
    if (!menuListData) return null;

    return menuListData.reduce((acc, route) => {
      const Component = route.component_path ? LazyComponent(route.component_path) : null;
      const routePath = route.exact === '1' ? route.url : `${route.url}/*`;

      if (Component) {
        const routeObject = { path: routePath, component: Component, exact: route.exact === '1' };
        if (route.display_only_logged_in === '1') {
          acc.privateRoutes.push(routeObject);
        } else {
          acc.publicRoutes.push(routeObject);
        }
      }
      return acc;
    }, { privateRoutes: [], publicRoutes: [] });
  }, [menuListData]);

  if (!routesConfig) return null;

  const { privateRoutes, publicRoutes } = routesConfig;

  return (
      <BrowserRouter>
        <HelmetComponent />
        <Suspense fallback={<Loader />}>
          <Routes>
            <Route path="" element={<Layout />}>
              {/* Public Routes */}
              {publicRoutes.map(({ path, component: Component, exact }) => (
                  <Route key={path} path={path} element={<Component />} exact={exact} />
              ))}

              {/* Private Routes */}
              <Route element={<RequireAuth allowedRoles={allowedRoles} />}>
                {privateRoutes.map(({ path, component: Component, exact }) => (
                    <Route key={path} path={path} element={<Component />} exact={exact} />
                ))}
              </Route>

              {/* Fallback Error Page */}
              <Route path="/*" element={<ErrorPage />} />
            </Route>
          </Routes>
        </Suspense>
      </BrowserRouter>
  );
}

export default App;
