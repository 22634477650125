import {
    PRODUCTS_LIST_DATA,
    PRODUCTS_DETAIL_DATA,
    ADD_TO_CART,
    REMOVE_FROM_CART,
    LOAD_CART,
    CLEAR_CART, UPDATE_QUANTITY_OF_CART, ORDER_LIST_DATA, PRODUCTS_DETAIL_ERROR, APPLIED_DISCOUNT
} from "../constant";
const initialState = {
    productsData: 'Loading',
    productDetailError:false,
    productDetailData: [],
    cartId:'',
    orderEventTypeId:7,
    orderListData:false,
    appliedDiscount:false,
    cartItems: JSON.parse(localStorage.getItem('cart')) || [],
}
export  const productReducer = (state = initialState, action) => {
    switch (action.type) {
       case PRODUCTS_LIST_DATA:
            return { ...state, productsData: action.payload };
       case PRODUCTS_DETAIL_DATA:
            return { ...state, productDetailData: action.payload };
       case PRODUCTS_DETAIL_ERROR:
            return { ...state, productDetailError: action.payload };
       case ORDER_LIST_DATA:
            return { ...state, orderListData: action.payload };
       case APPLIED_DISCOUNT:
            return { ...state, appliedDiscount: action.payload };
        case ADD_TO_CART:
            const updatedCart = [...state.cartItems, action.payload];
            !(action.user_id) && localStorage.setItem('cart', JSON.stringify(updatedCart));
            return { ...state, cartItems: updatedCart };
        case REMOVE_FROM_CART:
            const filteredCart = state.cartItems.filter(item => item.product_id !== action.payload);
            !(action.user_id) && localStorage.setItem('cart', JSON.stringify(filteredCart));
            return { ...state, cartItems: filteredCart };
        case UPDATE_QUANTITY_OF_CART:
            const updatedCountCart = state.cartItems.map(item => item.product_id === action.payload.product_id
                ? { ...item, quantity: action.payload.quantity }
                : item);
            !(action.user_id) && localStorage.setItem('cart', JSON.stringify(updatedCountCart));
            return { ...state, cartItems: updatedCountCart };
        case LOAD_CART:
            (action.cart_id) && localStorage.removeItem('cart');
            return { ...state, cartItems: action.payload ? action.payload : [],cartId:action.cart_id };
        case CLEAR_CART:
            !(action.user_id) && localStorage.removeItem('cart');
            return { ...state, cartItems: [] };
        default:
            return state
    }
}

export default productReducer;
