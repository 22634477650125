import {ADDRESS_LIST, CITY_LIST, STATE_LIST, USER_PROFILE_DATA, ZIP_CODE_LIST, DELETE_ADDRESS, UPDATE_ADDRESS} from "../constant";

const initialState = {
    stateList:false,
    cityList:false,
    zipCodeList:false,
    addressListData: [],
    userProfileData: false,
}
export  const commonReducer = (state = initialState, action) => {
    switch (action.type) {
       case STATE_LIST:
            return { ...state, stateList: action.payload };
       case CITY_LIST:
            return { ...state, cityList: action.payload };
       case ZIP_CODE_LIST:
            return { ...state, zipCodeList: action.payload };
       case ADDRESS_LIST:
            return { ...state, addressListData: action.payload };
       case USER_PROFILE_DATA:
            return { ...state, userProfileData: action.payload };
       case DELETE_ADDRESS:
            return {...state, addressListData: state.addressListData.filter(address => address.id !== action.payload) };
       case UPDATE_ADDRESS:
            return {...state, addressListData: state.addressListData.map(address => address.id === action.payload.id ? { ...address, ...action.payload.updatedAddress } : address )};
       default:
            return state
    }
}
