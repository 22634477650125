import React, { useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FaSignOutAlt, FaUser } from "react-icons/fa";
import { FaCartShopping, FaRightToBracket } from "react-icons/fa6";
import { useDispatch, useSelector } from "react-redux";
import useAuth from "../../../redux/hooks/useAuth";
import { actionToLogout } from "../../../redux/action";
import Tippy from "@tippyjs/react";
import 'tippy.js/dist/tippy.css';

const AuthNavBar = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { auth, setAuth } = useAuth();
    const cartItems = useSelector((state) => state.product.cartItems);

    // Calculate cart quantity
    const cartQuantity = cartItems?.reduce((total, item) => total + item.quantity, 0) || 0;

    // Handle logout
    const logout = useCallback(() => {
        dispatch(actionToLogout());
        localStorage.removeItem("accessToken");
        setAuth({});
        navigate('/login');
    }, [dispatch, navigate, setAuth]);

    return (
        <>
            {auth?.id ? (
                <>
                    <Link to='/my-account' aria-label={"My Account"}>
                        <Tippy content='My Account'>
                            <button className="relative p-3" aria-label={"my account link button"}>
                                <FaUser className="text-xl text-gray hover:text-primary dark:text-gray-400" />
                            </button>
                        </Tippy>
                    </Link>
                    <Tippy content='Log Out'>
                        <button className="relative p-3" onClick={logout} aria-label={"logout icon link"}>
                            <FaSignOutAlt className="text-xl text-gray hover:text-primary dark:text-gray-400" />
                        </button>
                    </Tippy>
                </>
            ) : (
                <Link to='/login'  aria-label={"Login"}>
                    <Tippy content='Log In'>
                        <button className="relative p-3" aria-label={"login icon link"}>
                            <FaRightToBracket className="text-xl text-gray hover:text-primary dark:text-gray-400" />
                        </button>
                    </Tippy>
                </Link>
            )}
            <Link to='/cart'  aria-label={"Cart"}>
                <Tippy content='Cart'>
                    <button className="relative p-3" aria-label={"cart icon link"}>
                        <FaCartShopping className="text-xl text-gray hover:text-primary dark:text-gray-400" />
                        {cartQuantity > 0 && (
                            <div className="w-4 h-4 bg-primary text-white rounded-full absolute top-0 right-0 flex items-center justify-center text-xs">
                                {cartQuantity}
                            </div>
                        )}
                    </button>
                </Tippy>
            </Link>
        </>
    );
};

export default AuthNavBar;
