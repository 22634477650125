import React, { useState, useMemo } from "react";
import MenuIcon from "../../../assets/images/navbar/menu_icon.png";
import { useSelector } from "react-redux";
import { Link } from 'react-router-dom';
import AuthNavBar from "./AuthNavBar";
import CompanyLogo from "./CompanyLogo";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSearch} from "@fortawesome/free-solid-svg-icons";
import SearchFilter from "./SearchFilter";

const Navbar = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const menuListData = useSelector((state) => state.webSetting.menuListData);

    // Memoize the entire menu to avoid recalculating on every render
    const memoizedMenu = useMemo(() => {
        if (!menuListData || menuListData.length === 0) return null;

        // Filter desktop and mobile menu items
        const desktopMenuItems = menuListData.filter(item =>
            item.show_in_web_navbar === '1' &&
            item.left_menu === '1' &&
            ['0', 'both'].includes(item.display_only_logged_in)
        );

        const mobileMenuItems = menuListData.filter(item =>
            item.show_in_mobile_navbar === '1' &&
            ['0', 'both'].includes(item.display_only_logged_in)
        );

        // Render desktop menu
        const desktopMenu = (
            <ul className="hidden xl:flex items-center gap-12 text-base">
                {desktopMenuItems.map(menuItem => (
                    <li className="p-3 hover:text-primary text-md rounded-md transition-all cursor-pointer"  key={menuItem?.id}>
                        <Link to={menuItem.url}>{menuItem.name}</Link>
                    </li>
                ))}

            </ul>
        );

        // Render mobile menu
        const mobileMenu = (
            <ul className="absolute xl:hidden top-24 left-0 w-full bg-white flex flex-col items-center gap-6 text-lg transform transition-transform opacity-100" style={{ transition: "transform 0.3s ease, opacity 0.3s ease" }}>
                {mobileMenuItems.map(menuItem => (
                    <li key={menuItem?.id} className="list-none w-full text-center p-4 hover:bg-primary hover:text-white transition-all cursor-pointer" onClick={() => setIsMenuOpen(false)}>
                        <Link to={menuItem.url}  > {menuItem.name}</Link>
                    </li>
                ))}
                <AuthNavBar />
                <div className='relative md:flex items-center justify-center gap-3 pb-4'>
                    <FontAwesomeIcon className='absolute pt-2 left-3 text-2xl text-gray w-5' icon={faSearch}/>
                    <SearchFilter />
                </div>
            </ul>
        );

        return { desktopMenu, mobileMenu };
    }, [menuListData]);

    if (!memoizedMenu) {
        return null;
    }

    return (
        <div>
            <header className="flex justify-between items-center text-black py-6 px-8 md:px-32 bg-white drop-shadow-md fixed">
                {/* Company Logo */}
                <CompanyLogo />
                {/* Desktop Menu */}
                {memoizedMenu.desktopMenu}

                <div className=' relative hidden xl:flex items-center gap-12 text-base'>
                    <FontAwesomeIcon className='absolute left-3 text-2xl text-gray w-5' icon={faSearch}/>
                    <SearchFilter />
                </div>

                {/* Authentication Navigation for Desktop */}
                <div className="hidden xl:flex items-center gap-12 text-base">
                    <AuthNavBar />
                </div>

                {/* Mobile Menu Toggle */}
                <img
                    src={MenuIcon}
                    alt="menu-open"
                    className="xl:hidden block text-5xl cursor-pointer w-6"
                    onClick={() => setIsMenuOpen(prev => !prev)}
                />

                {/* Mobile Menu */}
                {isMenuOpen && memoizedMenu.mobileMenu}
            </header>
        </div>
    );
};

export default React.memo(Navbar);

