import React from 'react';

const ErrorPage = () => (
    <div className='pt-24'>
    <div className="container">
        <h1>404 Page Not Found</h1>
    </div>
    </div>
);

export default ErrorPage;
