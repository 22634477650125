import React from "react";
import {useLocation, useNavigate, useSearchParams} from "react-router-dom";

const SearchFilter = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const location = useLocation(); // Get the current location
    const navigate = useNavigate();  // Used for navigating to the /products page

   const handleSearchChange = (e) => {
        const searchValue = e.target.value;
        if (searchValue) {
            setSearchParams({ search: searchValue });
        } else {
            searchParams.delete("search");
            setSearchParams(searchParams);
        }
    };
    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            const searchValue = e.target.value;
            if (location.pathname === "/products") {
                // If already on /products, just update the search param
                if (searchValue) {
                    setSearchParams({ search: searchValue });
                } else {
                    searchParams.delete("search");
                    setSearchParams(searchParams);
                }
            } else {
                // If not on /products, navigate to /products with the search param
                navigate(`/products?search=${searchValue}`);
            }
        }
    };

    return (
        <input
            type="text"
            placeholder="Search..."
            className="py-2 pl-10 rounded-xl border-2 border-white focus:bg-slate focus:outline-orange"
            onChange={handleSearchChange}
            onKeyDown ={handleKeyPress}  // Handle Enter key press
            value={searchParams.get("search") || ""}
        />
    );
};

export default SearchFilter;
