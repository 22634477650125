import React from "react";
import {Outlet, useLocation} from "react-router-dom";
import NavBar from "./navbar/NavBar";
import Footer from "./footer/Footer";
import {ToastContainer} from "react-toastify";
import {useSelector} from "react-redux";

function Layout() {
    const {pathname} = useLocation();
    const menuListData = useSelector((state) => state.webSetting.menuListData);
    // Early return if no menu data is available
    if (!menuListData || menuListData.length === 0) {
        return null;
    }
    return (
        <div className={"flex flex-col h-screen"}>
            <NavBar/>
            <Outlet/>
            {pathname !== '/products' && <Footer />}
            <ToastContainer  />
        </div>
    )
}

export default Layout;
